import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { HttpClient} from  '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { LoadingController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Usuario } from '../interfaces/interface.usuario';
import { Algoritmo } from '../interfaces/interface.algoritmo';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  is_test_finished:boolean = false;

  VERSION = '1.0.0';

  current_env = environment.environment;

  private audioPlayer:HTMLAudioElement = new Audio();

  api_url:string = environment.API_APK_URL + "/api/v1/private/tests/";
  login_url:string = environment.API_APK_URL + "/api/v1/auth/login";
  test_partial_url:string = environment.API_APK_URL + "/api/v1/private/tests_partial";
  tests_count:string = environment.API_APK_URL + "/api/v1/private/tests_count";
  alcoholimetro:string = environment.API_APK_URL + "/api/v1/private/tests/alcohol";
  cambiopin_url:string = environment.API_APK_URL + "/api/cambio_password";
  personal_data_url:string = environment.API_WEB_URL + "/users/personalData";
  email_url:string = environment.API_WEB_URL + "/users/email";
  validate_email_url:string = environment.API_WEB_URL + "/users/validateEmail";
  recover_url:string = environment.API_WEB_URL + "/auth/recover";
  algoritmo_data_url:string = environment.API_WEB_URL + "/algoritmo";
  rut:string='';
  nombre:string='';
  pin:string='';
  caducada:boolean = false;

  current_platform:any = 'browser'
  mobile_platform:any = '';

  usuario:Usuario = null;

  no_gps:any = false;

  timerTest: any;
  timerPersonalData: any;

  timerDisconnection: any;
  timerDisconeectionCount: number = 60;
  isDoingTest: boolean = false;

  testList;
  debug_envio_test = '';
  loading: any;

  alcohol: boolean = false;

  algoritmo:Algoritmo = {
    N1: {
      base: null,
      calculo_diferencia: null,
      umbral_reaccion: null,
    },
    N2: {
      error_0: null,
      error_1: null,
      error_2: null,
      error_3: null,
      error_igualmayor_4: null,
    },
    N4: {
      notoca_0: null,
      notoca_1: null,
      notoca_igualmayor_2: null,
    },
    BR: {
      mayorigual: null,
      menorigual: null,
    },
    RMB: {
      mayorigual: null,
      menorigual: null,
    },
    RMA: {
      mayorigual: null,
      menorigual: null,
    },
    AR: {
      mayorigual: null,
      porcentaje_umbral: null,
      porcentaje_estres: null,
    },
    TEST_2:{
      var1: null,
      var2: null,
      var3: null,
    },
    MULTIPLIER:{
      TEST_SEMAFORO: null,
      TEST_PSICOTECNICO: null,
      TEST_LABERINTO: null,
    }
  }

  DEBUG_MODE = true;
  test_overview:any = {};

  num_test:number=20;
  max_possible_score:number = 13;

  constructor(private  http_browser : HttpClient, private storage: Storage,public loadingController: LoadingController, private geolocation: Geolocation, private router: Router,private http: HTTP,public toastController: ToastController, private nativeAudio: NativeAudio, private screenOrientation: ScreenOrientation, public platform : Platform) {
    storage.create();
    this.iniciarTimerTest();
    this.iniciarTimerPersonalData();
    this.platform.ready().then(() => {


      console.table({
        width: this.platform.width() + 'w',
        height: this.platform.height() + 'h',
        environment: this.current_env,
      });

      if((this.platform.is('ios') || this.platform.is('android')) && !(this.platform.is('mobileweb') || this.platform.is('desktop'))){
        this.current_platform = 'native';
        if(this.platform.is('ios')) this.mobile_platform = 'ios';
        else this.mobile_platform = '';
      }else{
        this.current_platform = 'browser';
        if(this.platform.is('ios')) this.mobile_platform = 'ios';
        else this.mobile_platform = '';
      }
      console.log(this.platform.platforms());
      console.log(this.mobile_platform);
      if(this.current_platform === 'native'){
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        this.platform.backButton.subscribeWithPriority(10, () => {
          console.log('Behaviour cancelled');
        });
        /**
         * SI NO CARGA ESTE AUDIO ES UN BUG DE "NATIVE-AUDIO" SE DEBE MODIFICAR EN NODE-MODULES -> /node_modules/cordova-plugin-nativeaudio/src/android/
         * -> NativeAudio.java -> Buscar por "String fullPath" y cambiar "www" -> "public", Ionic usa public en vez de www en dispositivos nativos.
         * */
        this.nativeAudio.preloadSimple('click', 'assets/sounds/click.mp3').then(sucess => console.log('Click Audio cargado'), error => console.error(error));
      }
    });

  }

  async buttonPressed(){
    try {
      if(this.current_platform === 'native') await this.nativeAudio.play('click', success => console.log('Se reprodujo click'));
      else await this.playAudio('click');
    } catch (error) {
      console.log(error);
    }
  }


  playAudio(audio){
    return new Promise((resolve, reject) => {
      this.audioPlayer.src = `assets/sounds/${audio}.mp3`;
      this.audioPlayer.load();
      this.audioPlayer.play();
      this.audioPlayer.onended = () => {
        resolve(true);
      },
      this.audioPlayer.onerror = (error) => {
        reject(error);
      }
    });
  }

  async removeAllStorageKeys(){
    await this.storage.clear()
  }

  async getUser(){
    return await this.storage.get('john');
  }

  async getStorageKeys(){
    return await this.storage.keys();
  }

  async saveAlgoritmo(){
    await this.storage.set('Algoritmo', this.algoritmo);

  }

  async saveUser(){
    await this.showLoader();
    await this.storage.set(this.usuario.rut, this.usuario);
    await this.hideLoaderNow();
  }

  async saveLogin(login, password, type){
    await this.storage.set('login', {login: login, password: password, type: type});
  }

  async savePendingTests(tests){
    await this.storage.set('testList', tests);
  }
  async getPendingTests(){
    return await this.storage.get('testList');
  }

  async savePendingPersonalData(personal_data){
    await this.storage.set('personalDataList', personal_data);
  }
  async getPendingPersonalData(){
    return await this.storage.get('personalDataList');
  }

  async getCurrentUserLocation(){
    let aux_to_return = [];
    try{
      let current_device_location;
      current_device_location = await this.geolocation.getCurrentPosition({
        timeout: 5000,
      });
      // console.log(current_device_location);
      aux_to_return = [current_device_location.coords.latitude,current_device_location.coords.longitude]
      // console.log(aux_to_return);
      return aux_to_return;
    }catch(error){
      console.error(error)
      return aux_to_return;
    }
  }

  async presentToast(mensaje) {
    return console.log('Blocked Toast')
    const toast = await this.toastController.create({
      message: mensaje,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
  }

  iniciarTimerTest(){
    clearInterval(this.timerTest);
    this.timerTest = setInterval( () => {
    this.enviarTest();
    }, 30000);
  }

  iniciarTimerPersonalData(){
    clearInterval(this.timerPersonalData);
    this.timerPersonalData = setInterval( () => {
      this.enviarPersonalData();
    },25000);
  }

  async enviarPersonalDataSync(){
    // this.presentToast('Enviando su información personal...');
    if(this.current_platform === 'browser'){
      this.http_browser.put(this.personal_data_url,{
                                      rut: this.usuario.rut,
                                      genero: this.usuario.sexo,
                                      peso: this.usuario.peso,
                                      altura: this.usuario.altura,
                                      fechaNacimiento: this.usuario.fecha_de_nacimiento,
                                      tratamientoPresionArterial: this.usuario.tratamiento_presion_arterial},
                                      {headers: {"Content-Type":"application/json"}})
        .subscribe(async data => {
          this.presentToast('Información personal enviada con exito...');
      }, async error => {
        let personalDataList = await this.getPendingPersonalData();
        if(!personalDataList) personalDataList = [];
        personalDataList.push({
        token: this.usuario.token,
        rut: this.usuario.rut,
        genero: this.usuario.sexo,
        peso: this.usuario.peso,
        altura: this.usuario.altura,
        fechaNacimiento: this.usuario.fecha_de_nacimiento,
        tratamientoPresionArterial: this.usuario.tratamiento_presion_arterial
        });
        this.savePendingPersonalData(personalDataList);
      })
    }else{
      this.http.put(this.personal_data_url,{
                                  rut: this.usuario.rut,
                                  genero: this.usuario.sexo,
                                  peso: this.usuario.peso,
                                  altura: this.usuario.altura,
                                  fechaNacimiento: this.usuario.fecha_de_nacimiento,
                                  tratamientoPresionArterial: this.usuario.tratamiento_presion_arterial},
                                  {"Content-Type":"application/json"})
      .then(async data => {
        this.presentToast('Información personal enviada con exito...');
      }).catch(async error => {
        let personalDataList = await this.getPendingPersonalData();
        if(!personalDataList) personalDataList = [];
        personalDataList.push({
          token: this.usuario.token,
          rut: this.usuario.rut,
          genero: this.usuario.sexo,
          peso: this.usuario.peso,
          altura: this.usuario.altura,
          fechaNacimiento: this.usuario.fecha_de_nacimiento,
          tratamientoPresionArterial: this.usuario.tratamiento_presion_arterial
        });
        this.savePendingPersonalData(personalDataList);
      });
    }
  }

  async enviarPersonalData(){
    let personalDataList = await this.getPendingPersonalData();
    if(!personalDataList) return;
    if(personalDataList.length > 0){
      // this.presentToast('Intentando enviar su información personal pendiente...');
      if(this.current_platform === 'browser'){
        this.http_browser.put(this.personal_data_url, {
                                    rut: personalDataList[0].rut,
                                    genero: personalDataList[0].sexo,
                                    peso: personalDataList[0].peso,
                                    altura: personalDataList[0].altura,
                                    fechaNacimiento: personalDataList[0].fecha_de_nacimiento,
                                    tratamientoPresionArterial: personalDataList[0].tratamiento_presion_arterial},
        {headers: {"Content-Type":"application/json", "Authorization": `Bearer ${personalDataList[0].token}`}}).subscribe(data => {
        personalDataList.shift();
        this.savePendingPersonalData(personalDataList);
        },error => {console.error('Error al enviar la información personal.')});
      }else{
        this.http.setDataSerializer('json');
        this.http.put(this.personal_data_url, {
                                            rut: personalDataList[0].rut,
                                            genero: personalDataList[0].sexo,
                                            peso: personalDataList[0].peso,
                                            altura: personalDataList[0].altura,
                                            fechaNacimiento: personalDataList[0].fecha_de_nacimiento,
                                            tratamientoPresionArterial: personalDataList[0].tratamiento_presion_arterial},
        {"Content-Type":"application/json", "Authorization": `Bearer ${personalDataList[0].token}`}).then(data => {
          personalDataList.shift();
          this.savePendingPersonalData(personalDataList);
        }).catch(error => {console.error('Error al enviar la información personal.')});
      }
    }
  }

  async enviarEmailSync(email:string){
    try {
      if(this.current_platform === 'browser'){
        let response:any = await lastValueFrom(this.http_browser.put(this.email_url,{email: email, rut: this.usuario.rut},{headers: {"Content-Type":"application/json"}}));
        if(response.OK){
          this.presentToast('El email fue agregado con Éxito...');
        }else{
          throw Error('Error al enviar el email.');
        }
      }else{
        let response:any = await new Promise<any>((resolve, reject) => {
          this.http.put(this.email_url,{email: email, rut: this.usuario.rut},{"Content-Type":"application/json"})
          .then(async data => {
          resolve(data);
          this.presentToast('El email fue agregado con Éxito...');
        }).catch(async error => {
          reject(error);
          throw Error('Error al enviar el email.');
        })});
      }

      return {status:true};
    } catch (error) {
      console.error(error);
      return {status: false, message: error.error.error};
    }
  }

  async validarEmail(email:string, rut:string){
    try {
      if(this.current_platform === 'browser'){
        let response:any = await lastValueFrom(this.http_browser.post(this.validate_email_url,{email: email, rut: rut},{headers: {"Content-Type":"application/json"}}));
        if(!response.OK) throw Error('Error en validar email o contraseña');
      }else{
        let response:any = await new Promise<any>((resolve, reject) => {
          this.http.post(this.validate_email_url,{email: email, rut: rut},{"Content-Type":"application/json"})
          .then(async data => {
          resolve(data);
        }).catch(async error => {
          reject(error);
          throw Error('Error en validar email o contraseña');
        })});

        if(!response.OK) throw Error('Error en validar email o contraseña');
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  globalTimerDisconnection(){
    clearInterval(this.timerDisconnection);
    this.timerDisconnection = setInterval(() => {
      if(this.usuario){
        if(!this.isDoingTest){
          this.timerDisconeectionCount--;
        }
        if(this.timerDisconeectionCount <= 0){
          console.log('Desconectado por inactividad', this.timerDisconeectionCount);
          clearInterval(this.timerDisconnection);
          this.router.navigate(['login']);
        }
      }

    }, 1000);
  }

  async recoverPassword(rut:string, password:string){
    try {
      if(this.current_platform === 'browser'){
        let response:any = await lastValueFrom(this.http_browser.put(this.recover_url,{rut: rut, password: password},{headers: {"Content-Type":"application/json"}}));
        if(!response.OK) throw Error('Error al crear nueva contraseña');
      }else{
        let response:any = await new Promise<any>((resolve, reject) => {
          this.http.put(this.recover_url,{rut: rut, password: password},{"Content-Type":"application/json"})
          .then(async data => {
          resolve(data);
        }).catch(async error => {
          reject(error);
          throw Error('Error al crear nueva contraseña');
        })});

        if(!response.OK) throw Error('Error al crear nueva contraseña');
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async enviarTestSync(test){

    this.presentToast('Enviando resultados...');
    this.debug_envio_test += test.resultado + "\n" + test.traza;

    if(this.current_platform === 'browser'){

        this.http_browser.post(`${environment.API_APK_URL}/api/v1/private/tests/`,test,{headers:{"Content-Type":"application/json", "Authorization": `Bearer ${this.usuario.token}`}}).subscribe((data:any) => {
            // if(test.version !== this.version) this.router.navigate(['caducada']);
            // this.presentToast('Test enviado con exito!');

            const nombre = this.usuario.nombre.split(' ');

          if(test.resultado !== 'REPETIR TEST'){
            console.log("imprimir")

            const body = {
                nombre: nombre[1] + ", " + nombre[0],
                rut: test.rut,
                mandante: data.mandante,
                faena: data.faena,
                // supervisor: data.supervisor,
                nTrabajador: this.usuario.id,
                fecha: test.fecha + " / " + test.hora,
                resultado: `${test.resultado} (${test.resultado.split(' ').map(primera => primera[0].toUpperCase()).join('')})`,
            }

            this.http_browser.post('http://localhost/imprimir/imprimir.php', body, {headers: {"Content-Type":"application/json"}}).subscribe(data => {console.log(data)}, error => {
                console.error(error);
            });

          }


      },async error => {
        this.debug_envio_test = error+ ' '+ JSON.stringify(error);
        let testList = await this.getPendingTests();
        if(!testList) testList = [];
        testList.push({token: this.usuario.token, user_test: test});
        this.savePendingTests(testList);
      });
    }else{
      this.http.get('http://localhost/imprimir/imprimir.php', {
        nombre: this.usuario.nombre.split(' ')[0],
        apellido: this.usuario.nombre.split(' ').filter((name,index) => index !== 0).join(' '),
        resultado: test.resultado,
      }, {"Content-Type":"application/json"}).catch(error => {
        console.error(error);
      });

      this.http.setDataSerializer('json');
      this.http.post(`${environment.API_APK_URL}/api/v1/private/tests/`,test,{"Content-Type":"application/json", "Authorization": `Bearer ${this.usuario.token}`}).then(data => {
        // if(test.version !== this.version) this.router.navigate(['caducada']);
        this.presentToast('Test enviado con exito!');
      }).catch(async error => {
        this.debug_envio_test = error+ ' '+ JSON.stringify(error);
        let testList = await this.getPendingTests();
        if(!testList) testList = [];
        testList.push({token: this.usuario.token, user_test: test});
        this.savePendingTests(testList);
      });
    }
  }

  async enviarTest(){
    let testList = await this.getPendingTests();
    if(!testList) return;
    if(testList.length > 0){
      this.presentToast('Intantando enviar resultados pendientes...');
      if(this.current_platform === 'browser'){
        this.http_browser.post(`${environment.API_APK_URL}/api/v1/private/tests/`,testList[0].user_test,{headers:{"Content-Type":"application/json", "Authorization": `Bearer ${testList[0].token}`}}).subscribe(data => {
          this.presentToast('Test enviado con exito!');
          testList.shift();
          this.savePendingTests(testList);
        }, error => {
          this.debug_envio_test = error+ ' '+ JSON.stringify(error);
          this.presentToast('Error al enviar Test!');
          console.error('Error al enviar el test.', error)
        });
      }else{
        this.http.setDataSerializer('json');
        this.http.post(`${environment.API_APK_URL}/api/v1/private/tests/`,testList[0].user_test,{"Content-Type":"application/json", "Authorization": `Bearer ${testList[0].token}`}).then(data => {
          this.presentToast('Test enviado con exito!');
          testList.shift();
          this.savePendingTests(testList);
        }).catch(error => {
          this.debug_envio_test = error+ ' '+ JSON.stringify(error);
          this.presentToast('Error al enviar Test!');
          console.error('Error al enviar el test.', error)
        });
      }
    }
  }


  async showLoader() {
    if(this.loading) await this.loading.dismiss();
    this.loading = await this.loadingController.create({
      message: 'Espere un momento por favor',
      duration: 15000
    });
    await this.loading.present();
  }

  async customLoader(texto, duracionMS) {
    if(this.loading) await this.loading.dismiss();
    this.loading = await this.loadingController.create({
      message: texto,
      duration: duracionMS
    });
    await this.loading.present();
  }

  hideLoader() {
    setTimeout(() => {
      this.loading.dismiss();
    }, 1000)
  }

  async hideLoaderNow() {
    await this.loading.dismiss();
  }

  determinarSigPagina(data_user){
    // return this.router.navigate(['/test3']);
    this.is_test_finished = false;
    if(!data_user.sexo || !data_user.peso || !data_user.altura || !data_user.fecha_de_nacimiento) return this.router.navigate(['formulario-registro']);
    if(!data_user.email || data_user.email == '') return this.router.navigate(['registro-email']);
    // if(data_user.resultado_tests.cantidad_test_alto_riesgo === 1) return this.router.navigate(['semaforo_test']);
    if(data_user.resultado_tests.cantidad_test_alto_riesgo === 2 && data_user.encuesta.realiza_encuesta && !data_user.encuesta.encuesta_realizada && this.current_env !== 'totem') return this.router.navigate(['recomendacion-activa']);
    console.log(this.usuario);
    this.userNewStart();
    // if(this.current_env === 'totem') this.router.navigate(['fatiga_test']);
    if(this.current_env === 'totem') this.router.navigate(['alcoholimetro']);
    else this.router.navigate(['test-score']);        // this.router.navigate(['welcome']);
  }

  checkValidScore(test_to_eval){
    let notValid = true;
    switch(test_to_eval){
      case 'laberinto':
        for(let key of Object.keys(this.usuario.tests.test_laberinto.metrica_de_evaluacion)){
          if(this.usuario.tests.test_laberinto.metrica_de_evaluacion[key] < 0){
            notValid = false;
            break;
          }
        }
        break;
      case 'semaforo':
        for(let key of Object.keys(this.usuario.tests.test_semaforo.metrica_de_evaluacion)){
          if(this.usuario.tests.test_semaforo.metrica_de_evaluacion[key] < 0){
            notValid = false;
            break;
          }
        }
        break;
      default:
        notValid = false;
        break;
    }
    return notValid;
  }

  userNewStart(){
    this.usuario = {
      id: this.usuario.id,
      email: this.usuario.email,
      rut: this.usuario.rut,
      password: this.usuario.password,
      perfil: this.usuario.perfil,
      token: this.usuario.token,
      nombre: this.usuario.nombre,
      demo: this.usuario.demo,
      optimal: this.usuario.optimal,
      version: this.VERSION,
      nuevo_usuario: this.usuario.nuevo_usuario,
      fotoUsuario: '',
      sexo: this.usuario.sexo,
      tratamiento_presion_arterial: this.usuario.tratamiento_presion_arterial,
      peso: this.usuario.peso,
      altura: this.usuario.altura,
      fecha_de_nacimiento: this.usuario.fecha_de_nacimiento,
      latitud: -1,
      longitud: -1,
      nivel_estres: -1,
      bloqueo_test: this.usuario.bloqueo_test,
      test_actual: -1,
      alcohol: false,
      tests: {
        test_laberinto: {
          test_iniciado: false,
          test_terminado: false,
          metrica_de_evaluacion:{
            tiempo_resolucion: 0,
            ultimo_checkpoint: 0,
            cantidad_reinicio: 0,
            cantidad_desvio: 0,
            cantidad_colision: 0,
            porcentaje_dentro_linea: '0',
            porcentaje_fuera_linea: '0',
          }
        },
        test_2:{
          test_iniciado: false,
          test_terminado: false,
          metrica_de_evaluacion:{
            tiempo_resolucion: 0,
            capacidad_bimanual: 0,
            puntuacion_maxima_obtenida: 0,
            puntaje: 0,
            symmetrical_movement: {
              bola_1: [],
              bola_2: [],
            },
            prediccion_de_movimiento: {
              bola_1: [],
              bola_2: [],
            },
            recuperacion_de_centro: {
              bola_1: [],
              bola_2: [],
            },
          }
        },
        test_4:{
          test_iniciado: false,
          test_terminado: false,
          metrica_de_evaluacion:{
            finish_time:'0',
            total_error:0,
            total_time_out_road: '0',
            car_1:{
              collision_count:0,
              out_road_time: {
                out_start: 0,
                out_end: 0,
                accum: [],
                media: 0
              },
              symmetrical_movement: [],
              distance_from_center: {
                accum: [],
                media: 0
              },
            },
            car_2:{
              collision_count:0,
              out_road_time:{
                out_start: 0,
                out_end: 0,
                accum: [],
                media: 0
              },
              symmetrical_movement: [],
              distance_from_center: {
                accum: [],
                media: 0
              },
            },
          }
        },
        test_semaforo: {
          test_iniciado: false,
          test_terminado: false,
          metrica_de_evaluacion:{
            traza: [],
            position: [],
            resultado: {},
          }
        }
      },
      encuesta:{
        realiza_encuesta: false,
        encuesta_realizada: false,
        respuestas: []
      },
      resultado_tests:{
        timeStamp_ultimo_test: this.usuario.resultado_tests.timeStamp_ultimo_test,
        ultimo_test_verbose: this.usuario.resultado_tests.ultimo_test_verbose,
        ultimo_test: this.usuario.resultado_tests.ultimo_test,
        cantidad_test_alto_riesgo: 0,
        resultados: [],
      }
    }
    this.saveUser();
  }

  crearNuevoUsuarioLocal(data_user, password) : Usuario{
    return {
      id: data_user.content.user.id,
      email: data_user.content.user.email,
      rut: data_user.content.user.rut,
      password: password,
      perfil: data_user.content.user.perfil,
      token: data_user.content.tokens.accessToken.token,
      nombre: `${data_user.content.user.firstName} ${data_user.content.user.lastName}`,
      demo: data_user.content.user.demo,
      optimal: data_user.content.user.optimal,
      version: this.VERSION,
      nuevo_usuario: true,
      fotoUsuario: '',
      sexo: data_user.content.user.genero,
      tratamiento_presion_arterial: '',
      peso: data_user.content.user.peso,
      altura: data_user.content.user.altura,
      fecha_de_nacimiento: data_user.content.user.fechaNacimiento,
      latitud: -1,
      longitud: -1,
      nivel_estres: -1,
      bloqueo_test: -1,
      test_actual: -1,
      alcohol: false,
      tests: {
        test_laberinto: {
          test_iniciado: false,
          test_terminado: false,
          metrica_de_evaluacion:{
            tiempo_resolucion: 0,
            ultimo_checkpoint: 0,
            cantidad_reinicio: 0,
            cantidad_desvio: 0,
            cantidad_colision: 0,
            porcentaje_dentro_linea: '0',
            porcentaje_fuera_linea: '0',
          }
        },
        test_2:{
          test_iniciado: false,
          test_terminado: false,
          metrica_de_evaluacion:{
            tiempo_resolucion: 0,
            capacidad_bimanual: 0,
            puntuacion_maxima_obtenida: 0,
            puntaje: 0,
            symmetrical_movement: {
              bola_1: [],
              bola_2: [],
            },
            prediccion_de_movimiento: {
              bola_1: [],
              bola_2: [],
            },
            recuperacion_de_centro: {
              bola_1: [],
              bola_2: [],
            },
          }
        },
        test_4:{
          test_iniciado: false,
          test_terminado: false,
          metrica_de_evaluacion:{
            finish_time:'0',
            total_error:0,
            total_time_out_road: '0',
            car_1:{
              collision_count:0,
              out_road_time: {
                out_start: 0,
                out_end: 0,
                accum: [],
                media: 0
              },
              symmetrical_movement: [],
              distance_from_center: {
                accum: [],
                media: 0
              },
            },
            car_2:{
              collision_count:0,
              out_road_time:{
                out_start: 0,
                out_end: 0,
                accum: [],
                media: 0
              },
              symmetrical_movement: [],
              distance_from_center: {
                accum: [],
                media: 0
              },
            },
          }
        },
        test_semaforo: {
          test_iniciado: false,
          test_terminado: false,
          metrica_de_evaluacion:{
            traza: [],
            position: [],
            resultado: {},
          }
        }
      },
      encuesta:{
        realiza_encuesta: false,
        encuesta_realizada: false,
        respuestas: []
      },
      resultado_tests:{
        timeStamp_ultimo_test: -1,
        ultimo_test_verbose: '',
        ultimo_test: '',
        cantidad_test_alto_riesgo: 0,
        resultados: [],
      }
    }
  }
}
