export const environment = {
    production: true,
    environment: 'totem',
    API_APK_URL: "https://api.vimo.nettz.com",
    API_WEB_URL: "https://vimo.ubq.cl:8443",
};

// export const environment = {
//     production: true,
//     environment: 'totem',
//     API_APK_URL: "http://localhost:3001",
//     API_WEB_URL: "http://localhost:3002",
// };